<template>
  <el-row>
    <el-col :lg="{span: 10, offset: 7}">
      <el-card v-loading="isLoading">
        <template #header>
         <div>
           <h2 class="heading page-heading">
             {{ $t('page_post_create.title') }}
           </h2>
           <CurrentUnitInfo />
         </div>
        </template>
        <template #default>
          <el-form
            v-model="form"
            :rules="formRules"
            status-icon
            :model="form"
            ref="form"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('page_post_create.fields.title')" prop="title">
                  <el-input :maxLength="100" v-model="form.title" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('page_post_create.fields.photo')" prop="photo">
                  <v-file-input v-model="form.photo" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('page_post_create.fields.description')" prop="description">
                  <el-input :maxLength="200" v-model="form.description" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="content">
                  <label class="el-form-item__label el-form-item__label--float-none">
                    {{ $t('page_post_create.fields.content') }}
                  </label>
                  <v-text-editor @input="validateEditor" v-model="form.content" />
                </el-form-item>
              </el-col>
              <el-col class="card-actions" :span="24">
                <el-button type="primary" @click="handleSubmit">
                  {{ $t('page_post_create.btn_submit') }}
                </el-button>
                <el-button @click="goToUnitPage">
                  {{ $t('page_post_create.btn_cancel') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import VTextEditor from '../../../components/ui/VTextEditor'
import VFileInput from '../../../components/ui/v-file-input/VFileInput'

import {createNewPost} from '../shared/services/post-service'
import {
  UNIT_POSTS_ROUTE_NAME,
  POST_VIEW_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'
import CurrentUnitInfo from '../../units/components/current-unit-info/CurrentUnitInfo'


export default {
  name: 'PostCreate',
  components: {
    CurrentUnitInfo,
    VFileInput,
    VTextEditor,
  },
  data() {
    return {
      isLoading: false,
      form: {
        title: '',
        photo: '',
        description: '',
        content: '',
      },
      formRules: {
        title: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        description: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        content: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        const {unitID} = this.$route.params
        this.isLoading = true
        createNewPost(this.form, unitID)
            .then(({postID}) => {
              this.$router.push({
                name: POST_VIEW_ROUTE_NAME,
                params: {
                  unitID,
                  postID
                }
              })
            })
            .catch(() => {
              this.$message({
                message: `${this.$t('page_post_create.messages.create_error')}`,
                type: 'error'
              })
            })
            .finally(() => {
              this.isLoading = false
            })
      })
    },
    validateEditor() {
      this.$refs.form.validateField('content')
    },
    goToUnitPage() {
      const {unitID} = this.$route.params
      this.$router.push({name: UNIT_POSTS_ROUTE_NAME, params: {unitID}})
    }
  }
}
</script>
